import { Link } from "gatsby"
import * as React from "react"

type TagLinksProps = {
  tags: string[]
}

export const TagLinks = ({ tags }: TagLinksProps): JSX.Element => {
  return (
    <>
      <section className="tag_area">
        <ul>
          {tags.map((tag, index) => {
            return (
              <li key={`${tag}-${index}}`}>
                <Link to={`/tags/${tag}/`} itemProp="url">
                  <span>{tag}</span>
                </Link>
              </li>
            )
          })}
        </ul>
      </section>
    </>
  )
}
